<template>
  <div class="ul-container">
    <div style="width: 900px">
      <UserTop></UserTop>
      <div class="ul-content ul-tool">
        <div class="ul-tool-item" @click="onAddUpload">
          <img style="width: 58px; height: 43px" src="../assets/upload.png" />
          <div>上传新作业</div>
        </div>
        <div class="ul-btns">
          <div class="ul-btn" @click="onShowHelp"><img src="../assets/help.png"> 上传说明</div>
          <div class="ul-btn" style="margin-top:10px;" @click="onDownloadTemp"><img src="../assets/down.png"> 下载作业模板</div>
        </div>
      </div>
      <div class="ul-content ul-list">
        <div class="ul-list-title">已上传作业</div>
        <div class="ul-list-content">
          <template v-if="hws.length > 0">
            <div class="ul-hw-item" v-for="hw in hws" :key="hw.hkfid">
              <div class="ul-hw-item-icon" v-if="hw.hkfstatus == 1">
                <img src="../assets/non-status.png" />
                <div>等待接收</div>
              </div>
              <div class="ul-hw-item-icon" v-if="hw.hkfstatus == 2">
                <img src="../assets/yes-status.png" />
                <div>已接收</div>
              </div>
              <div class="ul-hw-text">
                <div class="ul-hw-text-title">{{ hw.hkftitle }}</div>
                <div class="ul-hw-text-cdate">{{ hw.cdateText }}</div>
              </div>
              <div class="ul-hw-item-icon" v-if="hw.hkfstatus == 2">
                <img src="../assets/del-no.png" />
                <div>删除</div>
              </div>
              <el-popconfirm
                :title="'确定要删除【' + hw.hkftitle + '】吗？'"
                @confirm="delFile(hw)"
              >
                <div
                  slot="reference"
                  class="ul-hw-item-icon ul-hw-item-del"
                  v-if="hw.hkfstatus == 1"
                >
                  <img src="../assets/del-yes.png" />
                  <div>删除</div>
                </div>
              </el-popconfirm>
            </div>
            <div style="margin-top: 20px; text-align: center">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next,total"
                :page-size="pc"
                :total="total"
              >
              </el-pagination>
            </div>
          </template>
          <template v-else>
            <div class="no-data">
              <img src="../assets/nodata.png" />
              <span>暂无内容</span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible"
  width="40%" title="上传说明">
      <div class="help-list">
        <div class="help-item">1.请您按照【作业模板】的要求准备作业文件。</div>
        <div class="help-item">2.请您将作业文件打包压缩，一个作业一个压缩包。</div>
        <div class="help-item">3.请您选择作业压缩文件（.rar）上传。</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import UserTop from "@/components/UserTop.vue";
import client from "../util/alioss";
import {
  getHomeworkFileList,
  downloadUploadHomeworkFileTemp,
  delHomeworkFile,
} from "@/api/upload";
export default {
  name: "UploadList",
  components: {
    UserTop,
  },
  data() {
    return {
      tea: null,
      hws: [],
      total: 0,
      pc: 10,
      pn: 1,
      dialogVisible:false
    };
  },
  props: {},
  methods: {
    onShowHelp() {
      this.dialogVisible = true
    },  
    delFile(hw) {
      console.log(hw, client);
      client.delete(hw.hkfpath).then((res) => {
        console.log("oss删除成功", res);
        delHomeworkFile({ hkfid: hw.hkfid }).then((rp) => {
          console.log("数据库删除成功", rp);
          if (rp.data.succ == 1) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.doList();
          }
        });
      });
    },
    onDownloadTemp() {
      downloadUploadHomeworkFileTemp().then((response) => {
        let blob = new Blob([response.data]);
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = "学校实践作业内容提交模板.docx"; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
        this.$message({
          message: "模板下载成功！",
          type: "success",
        });
      });
    },
    onAddUpload() {
      this.$router.push("/uploadAdd");
    },
    handleCurrentChange(v) {
      console.log(v);
      this.pn = v;
      this.doList();
    },
    doList() {
      getHomeworkFileList({ pn: this.pn, pc: this.pc }).then((res) => {
        console.log("作业列表返回：", res);
        if (res.data.succ == 1) {
          let page = res.data.data;
          if (page) {
            let hws = page.records;
            this.hws = hws;
            this.total = page.total;
          }
        }
      });
    },
  },
  mounted() {
    this.doList();
  },
};
</script>
<style lang="scss" scoped>
.help-list {
  font-size: 14px;
  .help-item {
    padding: 5px;
  }
}
.ul-list {
  .ul-list-title {
    padding: 20px 30px;
    font-size: 18px;
  }
  .ul-list-content {
    padding: 0 30px 20px 30px;
    min-height: 400px;
    .ul-hw-item {
      border-bottom: 1px solid #ccc;
      padding: 15px 20px;
      display: flex;
      .ul-hw-item-icon {
        width: 60px;
        text-align: center;

        font-size: 14px;
      }
      .ul-hw-text {
        flex: 1;
        margin-left: 30px;

        .ul-hw-text-title {
          font-size: 16px;
        }
        .ul-hw-text-cdate {
          font-size: 14px;
          margin-top: 7px;
        }
      }
      .ul-hw-item-del {
        cursor: pointer;
        font-size: 14px;
      }
    }
    .no-data {
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
    }
  }
}

.ul-tool {
  height: 100px;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .ul-tool-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    cursor: pointer;
    > div {
      font-size: 18px;
      margin-top: 7px;
      color: rgb(255,139,0);
    }
  }

  .ul-btns {
    position: absolute;
    right: 30px;
    font-size: 14px;

    .ul-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }
  }
}
</style>