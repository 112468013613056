import Vue from 'vue'
import VueRouter from 'vue-router';
import ToLogin from '../components/ToLogin'
import UploadList from '../components/UploadList'
import UploadAdd from '../components/UploadAdd'
import UserInfo from '../components/UserInfo'
Vue.use(VueRouter)
const routes = [
    { path: '/', component: ToLogin },
    { path: '/uploadList', component: UploadList },
    { path: '/userInfo', component: UserInfo },
    {path:'/uploadAdd', component: UploadAdd}
]
const router = new VueRouter({
    routes
})

export default router