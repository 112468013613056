<template>
  <div class="ul-container">
    <div style="width: 900px">
      <UserTop></UserTop>
      <div class="ul-content">
        <div class="ul-msg">
          <el-alert
            :title="
              upStatus == 0
                ? tipMsgDefault
                : upStatus == 1
                ? tipMsgIng
                : tipMsgSucc
            "
            :show-icon="true"
            type="info"
            :closable="false"
          >
          </el-alert>
        </div>
        <div class="ul-upload">
          <div class="ul-item" v-for="(file, index) in files" :key="file.name">
            <div class="ul-item-img">
              <img src="../assets/bg-file.png" />
            </div>
            <div class="ul-item-title">
              {{ file.name }}
            </div>
            <div class="ul-item-bbar">
              <div class="ul-item-bbar-ready" v-if="file.status == 0">
                待上传
              </div>
              <div class="ul-item-bbar-ing" v-if="file.status == 1">
                <el-progress
                  :percentage="file.percentage"
                  color="#48abf2"
                ></el-progress>
              </div>
              <div class="ul-item-bbar-succ" v-if="file.status == 2">
                上传成功
              </div>

              <div class="ul-item-bbar-fal" v-if="file.status == -1">
                <el-button @click="reStart(file, index)" size="mini"
                  >重新上传</el-button
                >
              </div>
            </div>
            <el-popconfirm
              title="确定要取消吗？"
              v-if="file.status != 2"
              @confirm="onDelClick(file, index)"
            >
              <img
                slot="reference"
                class="ul-item-del"
                src="../assets/bg-del.png"
              />
            </el-popconfirm>
            <div v-if="file.status == -1" class="ul-item-del-text">
              上传失败
            </div>
            
          </div>
          <div class="ul-add-btn" v-if="addBtnShow">
            <div @click="onSelectFile" class="ul-add-btn-img">
              <img src="../assets/bg-add.png" />
            </div>
            <input
              @change="handleFileChange"
              id="addFile"
              type="file"
              ref="addFile"
            />
          </div>
        </div>
      </div>
      <div class="ul-content">
        <div style="text-align: center">
          <el-button
            @click="onStartUpload"
            v-if="startUploadShow"
            type="primary"
            :disabled="files.length == 0"
            >开始上传</el-button
          >
          <el-button
            @click="onReturn"
            v-if="returnBtnShow"
            type="default"
            :disabled="returnBtnDisabled"
            >{{ returnText }}</el-button
          >
        </div>
        <!-- 
        <button id="submit" @click="onSubmit">上传</button>
        <button @click="addOne">添加一个</button>
        <button @click="changeStatus(1)">改变状态1</button>
        <button @click="changeStatus(2)">改变状态2</button>
        <button @click="changeStatus(-1)">改变状态-1</button>
        -->
      </div>
    </div>
  </div>
</template>
<script>
import UserTop from "@/components/UserTop.vue";
import { randomLenNum } from "../util/util";
import { addHomeworkFile } from "../api/upload";
const OSS = require("ali-oss");
//import client from '../util/alioss'
export default {
  name: "UploadAdd",
  data() {
    return {
      upStatus: 0, // 0选择文件阶段  1上传阶段  2完成阶段
      tipMsgDefault:
        "从本地选择压缩文件上传，可连续选择多个文件，选择完成后点击“开始上传”。",
      tipMsgIng: "正在上传作业，请不要关闭页面和浏览器。",
      tipMsgSucc: "所有作业上传成功后可点击“完成”。",
      files: [],
    };
  },
  computed: {
    addBtnShow() {
      return this.upStatus == 0;
    },
    startUploadShow() {
      // 1.未选择任何文件时 2.upStatus = 0 时
      return this.upStatus == 0;
    },
    returnBtnShow() {
      return true;
    },
    returnText() {
      return this.upStatus == 1 || this.upStatus == 2 ? "完成" : "返回";
    },
    returnBtnDisabled() {
      return this.upStatus == 1;
    },
  },
  props: {},
  components: {
    UserTop,
  },
  methods: {
    reStart(file, i) {
      this.upStatus = 1;
      this.startFile(file, i);
    },
    onDelClick(file, index) {
      if (file.status == 2) {
        return;
      }
      if (file.status == 1 && file.cpt) {
        //如果正在传，则停止
        console.log("正在上传，要停止。", file.name, file.cpt.uploadId);
        file.client
          .abortMultipartUpload(file.cpt.name, file.cpt.uploadId)
          .then((res) => {
            //this.files.splice(index, 1);
            file.percentage = 0;
            console.log("删除了。", res);
            this.checkFilesStatus();
          })
          .catch((err) => {
            console.log("abortMultipartUpload调用失败：", err);
          });
      } else {
        this.files.splice(index, 1);
      }
      let inputEle = this.$refs.addFile;
      if (inputEle) {
        inputEle.value = "";
      }
    },
    onReturn() {
      this.$router.push("/uploadList");
    },
    checkFilesStatus() {
      if (this.files.length > 0) {
        let finished = true;
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          if (file.status != 2 && file.status != -1) {
            finished = false;
          }
        }
        if (finished) {
          this.upStatus = 2;
        }
      } else {
        this.upStatus = 0;
      }
    },
    startFile(file, i) {
      file.status = 1;
      const client = new OSS({
        region: "oss-cn-beijing",
        accessKeyId: "LTAIknyzFZr26zYv",
        accessKeySecret: "kDnRVcpsGZmzNzd8tRV0NkzRqW5jd6",
        bucket: "chuangyizuoye01",
      });
      file.client = client;
      client
        .multipartUpload(file.path, file.data, {
          progress: (p, cpt, res) => {
            //let percentage = Math.round(p * 100) / 100
            let percentage = parseFloat(p).toFixed(2);
            file.percentage = Math.round(percentage * 100);
            console.log("正在上传:", p, percentage, cpt, res);
            file.cpt = cpt;
            this.$set(this.files, i, file);
          },
        })
        .then((res) => {
          console.log("multipartUpload调用成功：", res);
          addHomeworkFile({
            hkftitle: file.name,
            hkfpath: file.path,
          })
            .then((resp) => {
              if (resp.data.succ == 1) {
                console.log("添加数据库成功", resp);
                file.status = 2;
                this.$set(this.files, i, file);
                this.checkFilesStatus();
              } else {
                
                file.status = -1;
                this.$set(this.files, i, file);
                this.checkFilesStatus();
              }
            })
            .catch((err) => {
              console.log(err)
              file.status = -1;
              this.$set(this.files, i, file);
              this.checkFilesStatus();
            });
        })
        .catch((err) => {
          console.log("multipartUpload调用失败：", err, file.name);
          file.status = -1;
          this.$set(this.files, i, file);
          this.checkFilesStatus();
        });
    },
    onStartUpload() {
      this.upStatus = 1;
      if (this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          if (file.status == 0) {
            this.startFile(file, i);
          }
        }
      }
    },
    handleFileChange() {
      let inputEle = this.$refs.addFile;
      console.log(inputEle.files[0]);
      let data = inputEle.files[0];
      if (data) {
        let file = {};
        file.data = data;
        file.name = data.name;
        file.status = 0;
        file.percentage = 0;
        let path = randomLenNum(20, new Date()) + "/" + data.name;
        file.path = path;
        this.files.push(file);
      }
    },
    onSelectFile() {
      this.$refs.addFile.dispatchEvent(new MouseEvent("click"));
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
#addFile {
  display: none;
}
.ul-content {
  padding: 30px 30px;
  .ul-upload {
    margin-top: 30px;
    display: flex;
    //justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .ul-item {
      width: 140px;
      text-align: center;
      height: 100px;
      border: 1px solid #aaa;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      margin-right: 20px;
      padding: 5px;
      box-sizing: border-box;
      margin-bottom: 10px;
      .ul-item-del {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
      .ul-item-del-text {
        position: absolute;
        top: 0;
        left: 0;
        color: #f00;
        font-size: 12px;
      }
      .ul-item-title {
        font-size: 14px;
        margin-top: 5px;
        width: 110px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .ul-item-bbar {
        font-size: 14px;
        //margin-top: 10px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ul-item-bbar-ready {
          color: rgb(255, 139, 0);
        }
        .ul-item-bbar-succ {
          color: #48abf2;
        }
        .ul-item-bbar-ing {
          width: 100%;
        }
      }
    }
    .ul-add-btn {
      margin-bottom: 10px;
      border: 1px solid rgb(253, 196, 127);
      //padding: 10px 20px;
      text-align: center;
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;

      .ul-add-btn-img {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 64px;
          height: 64px;
        }
      }
    }
  }
}
</style>