<template>
  <div class="user-top">
    <div class="user-top-logo">
      <img src="../assets/logo.png" />
      <div class="user-top-logo-content">
        <div class="user-top-logo-title">创意作业</div>
        <div class="user-top-logo-desc">{{ schname }}</div>
      </div>
    </div>
    <div class="user-top-tool">
      <div class="user-top-tool-item" @click="toUserInfo">
        <i class="el-icon-user"></i>
        <div class="user-top-tool-text">{{ tname }}老师</div>
      </div>
      <el-popconfirm title="确定要退出吗？" @confirm="onLogout">
        <div class="user-top-tool-item" slot="reference">
          <i class="el-icon-switch-button"></i>
          <div class="user-top-tool-text">退出</div>
        </div>
      </el-popconfirm>
    </div>
  </div>
</template>
<script>
import { userLogout,getTeacherInfo } from "@/api/login";
import {getCookie} from '@/util/util';
export default {
  name: "UserTop",
  props: {
  },
  data () {
    return {
      tname:'',
      schname:''
    }
  },
  methods: {
    onLogout() {
      userLogout().then((res) => {
        console.log(res);
        this.$router.push("/");
      });
    },
    toUserInfo() {
        this.$router.push("/userInfo");
    }
  },
  mounted() {
    let schname = getCookie('schname')
    let tname = getCookie('tname')
    this.schname = schname
    this.tname = tname

    //每隔30秒，请求下后端，判断是否登录过期。
    let interval = setInterval(()=>{
      getTeacherInfo().then(res=>{
        console.log('setInterval',res)
      })
    },30000)
    this.$once('hook:beforeDestroy',()=>{
      clearInterval(interval)
      console.log('清除了interval')
    })
  }
};
</script>
<style lang="scss" scoped>
.user-top {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding: 0 30px;
  .user-top-logo {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
    }
    .user-top-logo-content {
      margin-left: 10px;
      .user-top-logo-title {
        font-size: 18px;
      }
      .user-top-logo-desc {
        font-size: 12px;
        margin-top: 3px;
      }
    }
  }

  .user-top-tool {
    display: flex;
    align-items: center;
    .user-top-tool-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;
      cursor: pointer;
      i {
        font-size: 18px;
      }
      .user-top-tool-text {
        font-size: 12px;
      }
    }
  }
}
</style>