<template>
  <div class="login-container">
    <div class="login-top">
      <img src="../assets/logo.png" />
      <span>创意作业</span>
    </div>
    <div class="login-body">
      <div class="login-left">
        <img src="../assets/left.png" />
      </div>
      <div class="login-form">
        <div class="login-form-title">用户登录</div>
        <el-form
        style="width:100%;"
          :model="user"
          status-icon
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item prop="ttel">
            <el-input
              :clearable="true"
              autocomplete="off"
              v-model="user.ttel"
              placeholder="请输入用户名"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item prop="tpassword">
            <el-input
              :clearable="true"
              :show-password="true"
              type="password"
              prefix-icon="el-icon-lock"
              v-model="user.tpassword"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          

          <el-form-item style="margin-bottom:0;">
            <el-button
              :loading="loading"
              style="width: 100%"
              type="primary"
              @click="onSubmit('ruleForm')"
              >登录</el-button
            >
          </el-form-item>
          <el-form-item style="margin-bottom:0;">
            <el-switch
              v-model="remb"
              active-text="记住用户名密码"
              inactive-text="">
            </el-switch>
          </el-form-item>
          
        </el-form>
      </div>
    </div>
    <div class="login-bbar">
      <div class="login-bbar-item">
        <img src="../assets/pc_icon.png">
        <div class="login-bbar-item-content">
          <div class="login-bbar-item-content-title">
            创意作业PC端
          </div>
          <div class="login-bbar-item-content-desc">
            用于教师上传各学科实践作业，帮助学校收集、管理实践作业。
          </div>
        </div>
      </div>

      <div class="login-bbar-item">
        <img src="../assets/mini_icon.png">
        <div class="login-bbar-item-content">
          <div class="login-bbar-item-content-title">
            创意作业移动端
          </div>
          <div class="login-bbar-item-content-desc">
            用于展示学校各学科实践作业，以及实践作业的分享、统计、研究。
          </div>
        </div>
      </div>
      <div class="login-bbar-item">
        <img src="../assets/gz_icon.png">
        <div class="login-bbar-item-content">
          <div class="login-bbar-item-content-title">
            创意作业公众号
          </div>
          <div class="login-bbar-item-content-desc">
            用于宣传、展示学校实践作业成果，分享实践作业最新研究资讯。
          </div>
        </div>
      </div>
    </div>
    <div class="login-footer">
      Copyright © 2021-2022 chuangyizuoye.com. All Rights Reserved. 京ICP备09114197号-3 北京千百悦教育科技有限公司 版权所有
    </div>
  </div>
</template>
<script>
import { login } from "@/api/login";
import {getCookie,setCookie} from '@/util/util';
export default {
  name: "ToLogin",
  data() {
    var validateTtel = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        callback();
      }
    };
    var validateTpassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      user: {
        ttel: "",
        tpassword: "",
      },
      remb:true,
      loading: false,
      rules: {
        ttel: [{ validator: validateTtel }],
        tpassword: [{ validator: validateTpassword }],
      },
    };
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          login(this.user.ttel, this.user.tpassword).then((res) => {
            console.log("登录返回", res);
            let data = res.data;
            if (data.succ == 0) {
              this.$alert(data.msg, "提示", {
                confirmButtonText: "确定",
                showClose: false,
              });
              this.loading = false;
            } else {
              if(this.remb) {
                setCookie('ttel',this.user.ttel)
                setCookie('tpassword',this.user.tpassword)
              }else {
                setCookie('ttel','')
                setCookie('tpassword','')
              }
              setCookie('tname',data.data.tname)
              setCookie('schname',data.data.sch.schname)
              this.$router.push("/uploadList");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  mounted() {
    const ttel = getCookie('ttel')
    const tpassword = getCookie('tpassword')
    if(ttel && tpassword) {
      this.user.ttel = ttel
      this.user.tpassword = tpassword
    }
  },
};
</script>
<style lang="scss" scoped>

.el-form-item {
  margin-bottom: 20px;
}
.login-container {
  .login-top {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
    }
    span {
      font-size: 22px;
      margin-left: 10px;
    }
  }
  .login-body {
    background-color: rgb(254, 180, 91);
    display: flex;
    justify-content: center;
    align-items: center;

    .login-left {
      width: 450px;
      height: 340px;
      display: flex;
      align-items: center;
      margin-right: 100px;
    }
    .login-form {
      background-color: #fff;
      width: 330px;
      height: 280px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 30px;
      .login-form-title {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }

  .login-bbar {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .login-bbar-item {
      display: flex;
      align-items: center;
      border: 1px solid rgb(254, 180, 91);
      padding: 10px;
      width: 300px;
      margin-right: 10px;
      .login-bbar-item-content {
        margin-left: 20px;
        .login-bbar-item-content-title {
          font-size: 16px;
        }
        .login-bbar-item-content-desc {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }
  .login-footer {
    background-color: rgb(247, 247, 247);
    text-align: center;
    padding: 15px 10px;
    margin-top: 20px;
    font-size: 13px;
  }
}
</style>