import axios from 'axios';
import router from './router'
axios.defaults.timeout = 30000;
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
    console.log(config)
    return config
}, error => {
    console.log('error：', error)
    return Promise.reject(error)
});

axios.interceptors.response.use(res => {
    //console.log('返回：', res)
    //const status = res.status;
    //const message = res.data.msg || '未知问题'
    if(res.data.succ == -1) {
        router.replace({path:'/'})
    }
    return res;
}, error => {
    console.log('error：', error)
    return Promise.reject(new Error(error));
});

export default axios;