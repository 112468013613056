import request from '@/util/axios';
export const login = (ttel,tpassword) => {
    return request({
        url:'/api/web/teacherLogin',
        method:'post',
        params:{
            ttel,tpassword
        }
    })
}

export const getTeacherInfo = () => {
    return request({
        url:'/api/web/getTeacherInfo',
        method:'get',
        params:{

        }
    })
}

export const userLogout = () => {
    return request({
        url:'/api/web/teacherLogout',
        method:'get'
    })
}

export const updateTeacherRealname = (tname) => {
    return request({
        url:'/api/web/updateTeacherRealname',
        method:'post',
        params:{
            tname
        }
    })
}

export const updateTeacherTel = (ttel) => {
    return request({
        url:'/api/web/updateTeacherTel',
        method:'post',
        params:{
            ttel
        }
    })
}

export const updateTeacherPassword = (newPassword,oldPassword) => {
    return request({
        url:'/api/web/updateTeacherPassword',
        method:'post',
        params:{
            oldPassword,newPassword
        }
    })
}

export const changeTeacherSchool = (schid) => {
    return request({
        url:'/api/web/changeTeacherSchool',
        method:'post',
        params:{
            schid
        }
    })
}