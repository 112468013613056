import request from '@/util/axios';
export const addHomeworkFile = (params) => {
    return request({
        url: '/api/web/addHomeworkFile',
        method: 'post',
        params: {
            ...params
        }
    })
}

export const getHomeworkFileList = (params) => {
    return request({
        url: '/api/web/getHomeworkFileList',
        method: 'get',
        params: {
            ...params
        }
    })
}

export const downloadUploadHomeworkFileTemp = () => {
    return request({
        url: '/api/web/downloadUploadHomeworkFileTemp',
        method: 'post',
        responseType: 'blob',
        params: {

        }
    })
}

export const delHomeworkFile = (params) => {
    return request({
        url:'/api/web/delHomeworkFile',
        method: 'post',
        params: {
            ...params
        }
    })
}