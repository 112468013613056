<template>
  <div class="ul-container">
    <div style="width: 900px">
      <UserTop></UserTop>
      <div class="ul-content">
        <div class="ul-info-title">个人中心</div>
        <div class="ul-info-list">
          <div class="ul-info-item">
            <div class="ul-info-item-title">姓名</div>
            <div class="ul-info-item-content">
              <div class="ul-info-item-text">{{ tname }}</div>
              <div class="ul-info-item-btn">
                <el-button
                  @click="onTnameClick"
                  type="primary"
                  size="mini"
                  round
                  >修改</el-button
                >
              </div>
            </div>
          </div>
          <div class="ul-info-item">
            <div class="ul-info-item-title">手机号</div>
            <div class="ul-info-item-content">
              <div class="ul-info-item-text">{{ ttel }}</div>
              <div class="ul-info-item-btn">
                <el-button @click="onTtelClick" type="primary" size="mini" round
                  >修改</el-button
                >
              </div>
            </div>
          </div>
          <div class="ul-info-item">
            <div class="ul-info-item-title">密码</div>
            <div class="ul-info-item-content">
              <div class="ul-info-item-text"></div>
              <div class="ul-info-item-btn">
                <el-button
                  @click="onPasswordClick"
                  type="primary"
                  size="mini"
                  round
                  >修改</el-button
                >
              </div>
            </div>
          </div>
          <div class="ul-info-item">
            <div class="ul-info-item-title">学校</div>
            <div class="ul-info-item-content">
              <div class="ul-info-item-text">{{ schname }}</div>
              <div class="ul-info-item-btn">
                <el-button
                  @click="onSchoolClick"
                  type="primary"
                  size="mini"
                  round
                  >切换</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ul-content">
        <div style="text-align: center; padding: 30px 30px">
          <el-button @click="onReturn" type="default">返回</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      center
      :close-on-click-modal="false"
      :visible.sync="tnameVisible"
      width="30%"
    >
      <el-form
        style="width: 100%"
        label-width="60px"
        :rules="rules"
        :model="user"
      >
        <el-form-item prop="tname" label="姓名">
          <el-input
            :clearable="true"
            autocomplete="off"
            v-model="user.tname"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button :loading="tnameLoading" @click="tnameSubmit" type="primary"
          >提交</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      center
      :close-on-click-modal="false"
      :visible.sync="ttelVisible"
      width="30%"
    >
      <el-form
        style="width: 100%"
        label-width="80px"
        :rules="rules"
        :model="user"
      >
        <el-form-item prop="ttel" label="手机号">
          <el-input
            :clearable="true"
            autocomplete="off"
            v-model="user.ttel"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button :loading="ttelLoading" @click="ttelSubmit" type="primary"
          >提交</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      center
      :close-on-click-modal="false"
      :visible.sync="passwordVisible"
      width="30%"
    >
      <el-form
        style="width: 100%"
        label-width="100px"
        :rules="rules"
        :model="user"
        ref="passwordForm"
      >
        <el-form-item prop="oldPassword" label="旧密码">
          <el-input
            :clearable="true"
            autocomplete="off"
            v-model="user.oldPassword"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="newPassword" label="新密码">
          <el-input
            :clearable="true"
            autocomplete="off"
            v-model="user.newPassword"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="newPassword2" label="确认新密码">
          <el-input
            :clearable="true"
            autocomplete="off"
            v-model="user.newPassword2"
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button
          :loading="passwordLoading"
          @click="passwordSubmit"
          type="primary"
          >提交</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      center
      title="选择要切换的学校"
      :close-on-click-modal="false"
      :visible.sync="schoolVisible"
      width="30%"
    >
      <div class="sch-list">
        <div @click="changeSchool(sch.schid,sch.schname)" :class="{'sch-item':true,'active':sch.schid == schid}" v-for="sch in schs" :key="sch.schid">
          <span>{{sch.schname}}</span>
          <i v-if="sch.schid == schid" class="el-icon-check"></i>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import UserTop from "@/components/UserTop.vue";
import { getCookie, setCookie } from "@/util/util";
import { getTeacherInfo,changeTeacherSchool } from "@/api/login";
import {
  updateTeacherRealname,
  updateTeacherTel,
  updateTeacherPassword,
} from "@/api/login";
export default {
  name: "UserInfo",
  components: {
    UserTop,
  },
  data() {
    return {
      tname: "",
      ttel: "",
      tnameVisible: false,
      tnameLoading: false,
      ttelVisible: false,
      ttelLoading: false,
      passwordVisible: false,
      passwordLoading: false,
      schoolVisible:false,
      user: {
        tname: "",
        ttel: "",
        oldPassword: "",
        newPassword: "",
        newPassword2: "",
      },
      schname: "",
      schs:[],
      schid:0,
      rules: {
        tname: [
          { required: true, message: "请输入真实姓名" },
          { min: 1, max: 20, message: "长度在 1 到 20 个字符" },
        ],
        ttel: [{ required: true, message: "请输入手机号" }],
        oldPassword: [{ required: true, message: "请输入旧密码" }],
        newPassword: [{ required: true, message: "请输入新密码" }],
        newPassword2: [{ required: true, message: "请输入确认新密码" }],
      },
    };
  },
  methods: {
    changeSchool(schid,schname) {
      if(schid != this.schid) {
        changeTeacherSchool(schid).then(res=>{
          let d = res.data
          if(d.succ == 1) {
            setCookie('schname',schname)
            this.schoolVisible = false
            this.$router.go(0)
          }
        })
      }else {
        this.schoolVisible = false
      }
      
    },
    onSchoolClick() {
      this.schoolVisible = true
    },
    onReturn() {
      this.$router.push("/uploadList");
    },
    onTnameClick() {
      this.tnameVisible = true;
    },
    onPasswordClick() {
      this.passwordVisible = true;
    },
    onTtelClick() {
      this.ttelVisible = true;
    },
    tnameSubmit() {
      if (this.user.tname) {
        this.tnameLoading = true;
        updateTeacherRealname(this.user.tname).then((res) => {
          if (res.data.succ == 1) {
            this.tnameVisible = false;
            this.$message({
              message: "姓名修改成功！",
              type: "success",
            });
            setCookie("tname", this.user.tname);
            this.tname = this.user.tname;
            this.tnameLoading = false;
          }
        });
      }
    },
    ttelSubmit() {
      if (this.user.ttel) {
        this.ttelLoading = true;
        updateTeacherTel(this.user.ttel).then((res) => {
          if (res.data.succ == 1) {
            this.ttelVisible = false;
            this.$message({
              message: "手机号修改成功！",
              type: "success",
            });
            setCookie("ttel", this.user.ttel);
            this.ttel = this.user.ttel;
            this.ttelLoading = false;
          } else if (res.data.succ == 0) {
            this.ttelLoading = false;
            this.$alert(res.data.msg, "提示", {
              confirmButtonText: "确定",
              showClose: false,
            });
          }
        });
      }
    },
    passwordSubmit() {
      this.$refs["passwordForm"].validate((valid) => {
        if (valid) {
          if (this.user.newPassword != this.user.newPassword2) {
            this.$alert("两次输入的新密码不一致。", "提示", {
              confirmButtonText: "确定",
              showClose: false,
            });
            return;
          }
          this.passwordLoading = true;
          updateTeacherPassword(
            this.user.newPassword,
            this.user.oldPassword
          ).then((res) => {
            if (res.data.succ == 1) {
              this.passwordVisible = false;
              this.$message({
                message: "密码修改成功！",
                type: "success",
              });
              this.passwordLoading = false;
            } else if (res.data.succ == 0) {
              this.passwordLoading = false;
              this.$alert(res.data.msg, "提示", {
                confirmButtonText: "确定",
                showClose: false,
              });
            }
          });
        }
      });
    },
  },
  mounted() {
    getTeacherInfo().then((res) => {
      let d = res.data;
      if (d.succ == 1) {
        let tea = d.data;
        this.schs = tea.schs
        this.schid = tea.sch.schid
        const ttel = getCookie("ttel");
        const tname = getCookie("tname");
        this.tname = tname;
        this.user.tname = tname;
        this.ttel = ttel || tea.ttel;
        this.user.ttel = ttel || tea.ttel;
        let schname = getCookie("schname");
        this.schname = schname;
      }
    });
  },
};
</script>
<style lang="scss" scoped>

.sch-list {
  .sch-item {
    font-size: 14px;
    padding: 10px;
    //border-top: 1px solid #ccc;
    //border-left: 1px solid #ccc;
    //border-right: 1px solid #ccc;
    i {
      margin-left: 20px;
    }
    cursor: pointer;
  }
  .sch-item.active {
    color: rgb(255, 139, 0);;
    //background-color: rgb(255, 139, 0);
  }
  .sch-item:last-child {
    //border-bottom: 1px solid #ccc;
  }
}
.el-form-item {
  margin-bottom: 20px;
}
.ul-info-title {
  padding: 30px 180px;
  font-size: 16px;
}
.ul-info-list {
  padding: 0 30px 30px 30px;
  font-size: 14px;
  .ul-info-item {
    display: flex;
    padding: 0 180px;
    align-items: center;
    margin-bottom: 20px;
    .ul-info-item-title {
      width: 60px;
      text-align: right;
      margin-right: 30px;
    }
    .ul-info-item-content {
      flex: 1;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      padding: 8px 0px;
      color: #aaa;
      .ul-info-item-text {
        flex: 1;
      }
      .ul-info-item-btn {
        width: 60px;
      }
    }
  }
}
</style>