<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}
.ul-container {
  background-color: rgb(254, 238, 218);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  min-height: 800px;

  .ul-content {
    background-color: #fff;
    margin-top: 5px;
  }
}

</style>
